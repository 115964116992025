<template>
  <div class="client-type-manager">
    <els-bread class="_bread"></els-bread>
    <div class="_header client-index">
      <span>
        <el-button size="small" type="primary" @click="handleadd">{{
          lang.addType
        }}</el-button>
      </span>
      <span>
        <el-input
          v-model="nValue"
          size="small"
          :placeholder="lang.terType"
          prefix-icon="el-icon-search"
        ></el-input>
      </span>
    </div>
    <div class="_content">
      <els-table
        ref="elsTab"
        :column="column"
        :onload="onload"
        :table="{ rowKey: 'id.id' }"
        :menuWidth="'120px'"
        header-row-style="height:50px;"
      >
        <template v-slot:menu="{ row }">
          <span @click="rowView(row)" style="color: #409eff">{{
            lang.view
          }}</span>
          <span class="client-line"></span>
          <span @click="rowDelete(row)" style="color: red">{{
            lang.delete
          }}</span>
        </template>
      </els-table>
    </div>
    <add-client
      ref="addclient"
      :title="lang.addType"
      :column="newColumn"
      @addOk="addOk"
    ></add-client>
    <desc-client
      ref="descClient"
      :title="lang.typeDetail"
      @isClose="addOk"
      :lastDicts="dicts"
    ></desc-client>
    <!-- <els-form-dialog :visible.sync="visible" :title="formTitle">
      <els-form :column="formColumn" v-model="formData"></els-form>
    </els-form-dialog>-->
  </div>
</template>
<script>
import addClient from "../components/clientManage/client-add/client-manager-add.vue";
import descClient from "../components/clientManage/client-add/client-desc-type.vue";
import elsTable from "../../components/els-table/main.vue";
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import elsBread from "../../components/els-breadcrumb/main.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
// api
import { getDict } from "@/api/com/iot-dict.js";
import mixinsI18n from "@/mixins/i18n.js";
import {
  addDeviceType,
  deleteDeviceType,
  getDeviceTypeById,
  getDeviceTypes,
  deleteMqttTopic,
  addMqttTopic,
  getMqttTopics,
  saveMqttTopic,
  findProtocols,
} from "@/api/manager/client-type-manage.js";
// import { hooks } from "../../components/els-form/util";

export default {
  name: "client-type-manager",
  mixins: [mixinsI18n],
  components: {
    elsTable,
    elsBread,
    elsFormDialog,
    elsForm,
    addClient,
    descClient,
  },
  provide() {
    return { lastDicts: this.dicts };
  },
  data: () => {
    return {
      nValue: "",
      protocals: [],
      columnBread: [],
      visible: false,
      formData: {},
      dicts: {
        networkingMode: [],
        protocol: [],
        accessType: [],
        dataType: [],
        mqttOperationAutherity: [],
        mqttTopicType: [],
      },
      clientDicts: {
        applicationsId: undefined,
        applications: [],
        deviceProfiles: [],
        deviceProfilesDisabled: true,
      },
      PAGE_NAME: "clientTypePage",
    };
  },
  watch: {
    nValue(v) {
      const d = v === "" ? "0" : v;
      if (d) this.$refs.elsTab.apiOnload();
    },
  },
  computed: {
    ...mapState(["breadMenus"]),
    ...mapGetters(["menus"]),
    column() {
      const dicts = this.dicts;
      const d = [
        { prop: "deviceTypeName", label: this.lang.terType, minWidth: 180 },
        {
          prop: "networkingMode",
          label: this.lang.netMode,
          dict: dicts.networkingMode,
          minWidth: 180,
        },
        {
          prop: "protocol",
          label: this.lang.conProt,
          dict: dicts.protocol,
          minWidth: 120,
        },
        {
          prop: "accessType",
          label: this.lang.auMode,
          dict: dicts.accessType,
          minWidth: 120,
        },
        {
          prop: "dataType",
          label: this.lang.dataFormat,
          dict: dicts.dataType,
          minWidth: 65,
        },
        {
          prop: "createdTime",
          label: this.lang.createTime,
          valueType: "date",
          minWidth: 120,
        },
      ];
      return d;
    },
    newColumn() {
      const dict = this.dicts;
      const pro = this.protocals;
      //
      const d = [
        {
          tag: "el-input",
          label: this.lang.terType,
          prop: "deviceTypeName",
          required: true,
          rules: [{ max: 20, message: this.lang.max20, trigger: "blur" }],
        },
        {
          tag: "el-input",
          label: this.lang.category,
          prop: "typeClass",
          rules: [{ max: 30, message: this.lang.max30, trigger: "blur" }],
        },
        {
          tag: "el-select",
          label: this.lang.netMode,
          placeholder: this.lang.pleaseSelect,
          required: true,
          trigger: "change",
          prop: "networkingMode",
          cls: dict.networkingMode,
        },
        {
          tag: "el-select",
          label: this.lang.proType,
          placeholder: this.lang.pleaseSelect,
          required: true,
          trigger: "change",
          prop: "protocol",
          cls: pro,
        },
        {
          tag: "el-select",
          label: this.lang.auMode,
          placeholder: this.lang.pleaseSelect,
          required: true,
          prop: "accessType",
          trigger: "change",
          cls: dict.accessType,
        },
        {
          tag: "el-select",
          label: this.lang.dataFormat,
          placeholder: this.lang.pleaseSelect,
          required: true,
          prop: "dataType",
          trigger: "change",
          cls: dict.dataType,
        },
        {
          tag: "el-input",
          label: this.lang.describe,
          prop: "describe",
          type: "textarea",
          rules: [{ max: 50, message: this.lang.max50, trigger: "blur" }],
        },
      ];
      return d;
    },
    // formColumn() {
    //   const dicts = this.dicts;
    //   const type = {
    //     tag: "el-input",
    //     label: "设备类型",
    //     prop: "deviceTypeName",
    //   };
    //   const net = {
    //     tag: "el-select",
    //     label: "连网方式",
    //     prop: "networkingMode",
    //     cls: dicts.networkingMode,
    //   };
    //   const access = {
    //     tag: "el-select",
    //     label: "认证方式",
    //     prop: "accessType",
    //     cls: dicts.accessType,
    //   };
    //   const data = {
    //     tag: "el-select",
    //     label: "数据格式",
    //     prop: "dataStyle",
    //     cls: dicts.dataType,
    //   };
    //   const desc = { tag: "el-input", type: "textarea" };
    //   return [type, net, access, data, desc];
    // },
  },
  mounted() {
    this.columnBread = this.breadMenus;
  },
  methods: {
    ...mapMutations("clientType", ["setRow"]),
    addOk() {
      this.$refs.elsTab.apiOnload();
    },
    async findPro() {
      const res = await findProtocols();
      if (res) {
        this.protocals = res.map((item) => ({
          label: item.name,
          value: item.name,
        }));
      }
    },
    async onload(page, query = {}) {
      try {
        const pn = page.pageNum;
        const ps = page.pageSize;
        const row = page.row;
        const p = { limit: ps, textSearch: this.nValue, pageNum: pn };
        // if (pn > 1 && row) {
        //   p.idOffset = row.id;
        // }
        const res = await getDeviceTypes(p);
        let list = [];
        let total = 0;
        if (!!this.nValue && this.nValue === "_") {
          list = res.data.filter((item) => {
            return String(item.name).indexOf(this.nValue) > -1;
          });
          total = list.length;
        } else {
          list = res.data;
          total = res.totalCount || res.data.length;
        }
        // list.forEach((item) => {
        //   const d = item.dataType.split("_");
        //   const n = item.networkingMode.split("_");
        //   const p = item.protocol.split("_");
        //   const a = item.accessType.split("_");
        //   item.dataType = d[d.length - 1];
        //   item.networkingMode = n[n.length - 1];
        //   item.protocol = p[p.length - 1];
        //   item.accessType = a[a.length - 1];
        // });
        let reg = /[`~!@#$%^&*()+<>?:"{},.\/;'[\]]/im;
        const testValue = reg.test(this.nValue);
        return {
          list: testValue
            ? []
            : list.map((item) => ({
                ...item,
                sourceId: item.id,
                id: item.id.id,
                nId: item.id,
              })),
          total: testValue ? 0 : total,
        };
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    async addDeviceType(d = {}) {
      try {
        const res = await addDeviceType(d);
      } catch (e) {
        console.log(e);
      }
    },
    async getDeviceTypeById(id) {
      try {
        const res = await getDeviceTypeById(id);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteMqttTopic(id) {
      try {
        const res = await deleteMqttTopic({ id });
      } catch (e) {
        console.log(e);
      }
    },
    async addMqttTopic(d = {}) {
      try {
        const res = await addMqttTopic(d);
      } catch (e) {
        console.log(e);
      }
    },
    async getMqttTopics(params = {}) {
      try {
        const res = await getMqttTopics(params);
      } catch (e) {
        console.log(e);
      }
    },
    async saveMqttTopic(d = {}) {
      try {
        const res = await saveMqttTopic(d);
      } catch (e) {
        console.log(e);
      }
    },
    async initDict() {
      try {
        Object.keys(this.dicts).forEach(async (k) => {
          const res = await getDict(k);
          if (res) {
            this.dicts[k] = res.map((item) => ({
              label: item.name,
              value: item.type + "_" + item.value,
            }));
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    handleadd() {
      this.$refs.addclient.clientVisible = true;
    },
    rowView(row) {
      this.$refs.descClient.dialogVisible = true;
      this.setRow(row);
    },
    // 删除
    async rowDelete(row) {
      this.$confirm(this.lang.delMsg, this.lang.tips, {
        confirmButtonText: this.lang.confirm,
        cancelButtonText: this.lang.cancel,
        type: "warning",
      })
        .then(() => {
          deleteDeviceType(row.id).then((res) => {
            this.$message({
              message: this.lang.delSuccess,
              type: "success",
            });
            this.$refs.elsTab.apiOnload();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.initDict();
    this.findPro();
  },
};
</script>
<style scoped lang="scss">
.client-type-manager {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .client-bread {
    // background: #fff;
    // padding: 20px;
    margin: 24px;
  }
  .client-index {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
    }
    .client-line {
      margin: 0px 8px;
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #ddd;
    }
}
</style>
